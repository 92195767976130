/* You can add global styles to this file, and also import other style files */

$day-closed-danger: #d13438;
$day-closed-danger-hover: #e63d42;

html {
  body {
    margin: 0;

    background: url(./../assets/bgLeft.png) bottom left/25vw no-repeat fixed,
      url(./../assets/bgRight.png) bottom right/25vw no-repeat fixed,
      url(./../assets/logo.png) center center/25vw no-repeat fixed,
      linear-gradient(45deg, rgba(55,151,212,0.35) 0%, rgba(30,100,172,0.35) 50%, rgba(39,42,149,0.2) 100%);

    &.standalone {
      background: url(./../assets/bgLeft.png) bottom left/25vw no-repeat fixed,
        url(./../assets/bgRight.png) bottom right/25vw no-repeat fixed,
        linear-gradient(45deg, rgba(55,151,212,0.35) 0%, rgba(30,100,172,0.35) 50%, rgba(39,42,149,0.2) 100%);
    }

    @media (max-width: $grid-breakpoint-sm) {

      &, &.standalone {
        background: url(./../assets/bgLeft.png) bottom left/30vw no-repeat fixed,
          url(./../assets/bgRight.png) bottom right/70vw no-repeat fixed,
          linear-gradient(45deg, rgba(55,151,212,0.35) 0%, rgba(30,100,172,0.35) 50%, rgba(39,42,149,0.2) 100%);
      }
    }

    h1, h2, h3 {
      font-weight: 500;
    }

    .topbar {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 1;
      max-height: $topbar-max-height;

      img#logo {
        margin-top: calc(2px - $p-menubar-padding-inferred);
        margin-bottom: -$p-menubar-padding-inferred;
        max-height: calc($topbar-max-height + 2px + 2 * $p-menubar-padding-inferred);
      }

      img#org-name {
        margin-left: 10px;
        max-height: 40px;
      }

      .p-breadcrumb {
        border: initial;
        background: initial;
        padding: initial;
        margin-left: var(--content-padding);
      }

      p-menubar {
        .p-menubar {
          p-menubarsub {
            margin-left: auto;
            margin-right: 1rem;
          }

          .p-menubar-end {
            margin-left: initial;
          }

          &.customized {
            @include component-backdrop-max;
            border: none;

            .p-menubar-button {
              .pi {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }

    #main-content-container {
      margin-top: calc($topbar-max-height + 1rem) !important;
    }

    .p-card {
      @include component-backdrop;

      &.paddingless-card {
        .p-card-body {
          padding: 0;

          .p-card-content {
            padding: 0;
          }
        }
      }

      &.project-card {
        a.main-link {
          display: block;
          padding: 1rem;
          text-decoration: none;
          color: inherit;
          height: 10rem;

          &:hover {
            color: inherit;
          }

          .titles {
            .project-title {
              margin-top: 0;
              margin-bottom: 0.5rem;
            }

            .starting {
              font-style: italic;
              font-size: smaller;

              .date {
                font-style: normal;
                font-size: initial;
              }
            }
          }
        }

        .actions {
          display: flex;
          gap: 0 5px;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 0.5rem;
        }
      }
    }

    .p-treetable {
      .p-treetable-header {
        @include component-backdrop;
        border-bottom-width: 2px;
      }

      .p-treetable-thead,
      .p-treetable-tbody {
        > tr {
          @include component-backdrop;

          > td {
            border: initial;
          }

          > th,
          > td {
            background: initial;
          }
        }
      }
    }

    .p-treeselect{
      width: 100%;
    }
    .p-calendar{
      display: block;
      .p-inputtext{
        width: 100%;
      }
    }
    .p-dropdown{
      width: 100%;
    }
    .p-splitbutton {
      &.zero-padding .p-splitbutton-defaultbutton {
        padding: 0;
      }
    }
  }
}

.day-closed{
  background: $day-closed-danger;
  &:hover{
    background: $day-closed-danger-hover;
  }
  .p-menuitem-link:hover{
    background: transparent !important;
  }
  .p-menuitem-icon{
    color: white !important;
  }
  .p-menuitem-text{
    color: white !important
  }
}

.p-tree-wrapper {
  > ul{
    > p-treenode > li{
      font-weight: bold;
    }
  }
}

.p-tree-wrapper {
  li{
    ul{
      li{
        font-weight: normal;
      }
    }
  }
}
