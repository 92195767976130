/* You can add global styles to this file, and also import other style files */
// @import "./primeflex.scss";

// @import "../../node_modules/primeicons/primeicons.css";
// @import "../../node_modules/primeng/resources/themes/fluent-light/theme.css";
// @import "../../node_modules/primeng/resources/primeng.min.css";

@import "./_custom-variables.scss";
@import "./_utilities.scss";
@import "./_primeng-customizatons.scss";
@import "./_custom-global-styles.scss";