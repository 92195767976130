$grid-breakpoint-sm: 576px;

@mixin component-backdrop {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
}

@mixin component-backdrop-max {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
}

.component-backdrop {
    @include component-backdrop;
}

.component-backdrop-max {
    @include component-backdrop-max;
}

.component-backdrop-hover {

    transform: background-color 5s;

    &:hover {
        @include component-backdrop;
    }
}

.hover-grow-1 {
    transition: transform 200ms;

    :hover {
        transform: scale(1.1);
    }
}

.hide-on-topbar-collapse {
    @media (max-width: $topbar-collapse-breakpoint) {
        display: none !important;
    }
}

.standalone-page-width {
    width: calc(100% - 0.5rem);
    max-width: 600px;
}