/* You can add global styles to this file, and also import other style files */

$progress-bar-danger: #d13438;
$progress-bar-warn: #498205;
$progress-bar-info: #ce7efc;

.p-menubar {
    .p-menubar-start {
        display: flex;
        align-items: center;
        margin-right: 1rem;
    }
}

.p-dialog {

    &.p-confirm-dialog {
        .p-confirm-dialog-message {
            margin-left: 0;
        }
    }

    .p-dialog-footer {

        flex-direction: row-reverse;
        display: flex;

        button {
            margin: 0;

            + button {
                margin-right: 0.5rem;
            }
        }
    }
}

.p-tabview{
  .p-tabview-nav{
    li{
      .p-tabview-nav-link{
        margin: 0;
      }
    }
  }
}

.p-progressbar {
  .p-progressbar-label{
    display: flex !important;
  }
}

.progress-danger{
  .p-progressbar-value{
    background: $progress-bar-danger;
  }
}

.progress-warn{
  .p-progressbar-value{
    background: $progress-bar-warn;
  }
}

.progress-info{
  .p-progressbar-value{
    background: $progress-bar-info;
  }
}

.filter-buttons{
  overflow-x: scroll;
  .p-buttonset{
    display: flex;
    flex-wrap: nowrap;
    .p-button{
      flex-grow: 1;
    }
  }
}
